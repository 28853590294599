/* @codingStandardsIgnoreFile */

/* NokiaPureHeadline */
@font-face {
    font-family: "NokiaPureHeadline";
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/nokia/NokiaPureHeadline-Regular.eot');
    src: url('../fonts/nokia/NokiaPureHeadline-Regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nokia/NokiaPureHeadline-Regular.woff') format('woff'), /* Pretty Modern Browsers */
    url('../fonts/nokia/NokiaPureHeadline-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nokia/NokiaPureHeadline-Regular.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: "NokiaPureHeadline";
    font-weight: 300;
    font-style: normal;
    src: url('../fonts/nokia/NokiaPureHeadline-Light.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/nokia/NokiaPureHeadline-Light.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nokia/NokiaPureHeadline-Light.woff') format('woff'), /* Pretty Modern Browsers */
    url('../fonts/nokia/NokiaPureHeadline-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nokia/NokiaPureHeadline-Light.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: "NokiaPureHeadline";
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/nokia/NokiaPureHeadline-Bold.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/nokia/NokiaPureHeadline-Bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nokia/NokiaPureHeadline-Bold.woff') format('woff'), /* Pretty Modern Browsers */
    url('../fonts/nokia/NokiaPureHeadline-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nokia/NokiaPureHeadline-Bold.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

/* NokiaPureText */
@font-face {
  font-family: "NokiaPureText";
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/nokia/NokiaPureText-Light.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/nokia/NokiaPureText-Light.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nokia/NokiaPureText-Light.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/nokia/NokiaPureText-Light.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nokia/NokiaPureText-Light.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: "NokiaPureText";
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/nokia/NokiaPureText-Regular.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/nokia/NokiaPureText-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nokia/NokiaPureText-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/nokia/NokiaPureText-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nokia/NokiaPureText-Regular.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: "NokiaPureText";
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/nokia/NokiaPureText-Bold.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/nokia/NokiaPureText-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nokia/NokiaPureText-Bold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/nokia/NokiaPureText-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nokia/NokiaPureText-Bold.svg#svgFontName') format('svg');
  /* Legacy iOS */
}